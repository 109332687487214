import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';

import {
 Input, Select, Checkbox, FormFooterActions,
} from '@atoms';
import { msgs, statusOptions } from '@config/conf';
import {
  formatDateWithoutTime,
  formatToPercentage,
  validateMaxValue,
} from '@containers/components/helpers';
import { sanitizeNumber } from '@lib/number';

const { fieldError } = msgs;

const handleValidation = (value, limit) => {
  const parsedValue = parseFloat(value);
  if (!parsedValue) {
    return fieldError;
  }
  return validateMaxValue(value, limit);
};

const PioForm = ({ data, redirectUrl, onSubmit }) => {
  const {
 control, reset, handleSubmit, watch,
} = useForm();

  const { pioMaxRate } = watch();
  const hasPioId = data?.pioid;

  const fieldPercentage = fieldName => (hasPioId && fieldName ? formatToPercentage(fieldName * 100) : '');

  useEffect(() => {
    const {
      pioCreditingRate,
      pioParticipationRate,
      pioMinRate,
      pioMaxRate,
      fiaCapRate,
      pioLastUpdatedOn,
    } = data || {};

    reset({
      ...data,
      pioCreditingRate: fieldPercentage(pioCreditingRate),
      pioParticipationRate: fieldPercentage(pioParticipationRate),
      pioMinRate: fieldPercentage(pioMinRate),
      pioMaxRate: fieldPercentage(pioMaxRate),
      fiaCapRate: fieldPercentage(fiaCapRate),
      pioLastUpdatedOn: pioLastUpdatedOn
        ? formatDateWithoutTime(pioLastUpdatedOn)
        : '',
    });
  }, [data]);

  const handleRateValidation = useCallback(
    (value) => {
      const minRate = parseFloat(sanitizeNumber(value));
      const maxRate = parseFloat(sanitizeNumber(pioMaxRate));
      if (!minRate) {
        return fieldError;
      }
      if (minRate >= maxRate) {
        return 'Must be less than maximum rate.';
      }
      return true;
    },
    [pioMaxRate],
  );

  const PiosFormFields = useMemo(
    () => [
      {
        label: 'Product Name',
        name: 'productName',
        isRequired: true,
        readOnly: true,
      },
      {
        label: 'Investment Option Name',
        name: 'pioName',
        maxLength: 200,
        isRequired: true,
      },
      {
        label: 'Investment Option Code',
        name: 'carrierPIOCode',
        maxLength: 50,
        isRequired: true,
      },
      {
        label: 'Method',
        name: 'pioMethod',
        maxLength: 50,
      },
      {
        label: 'Duration',
        name: 'pioDuration',
        maxLength: 50,
      },
      {
        label: 'Crediting Rate',
        name: 'pioCreditingRate',
        maxLength: 10,
        isRequired: true,
        type: 'percentage',
        validate: value => handleValidation(value),
      },
      {
        label: 'Participation Rate',
        name: 'pioParticipationRate',
        maxLength: 10,
        isRequired: true,
        type: 'percentage',
        validate: value => handleValidation(value, 1000),
      },
      {
        label: 'Cap Rate',
        name: 'fiaCapRate',
        maxLength: 10,
        isRequired: true,
        type: 'percentage',
        validate: value => handleValidation(value),
        formatter: { shouldPercentage: true, numericOnly: true },
      },
      {
        label: 'Minimum Rate',
        name: 'pioMinRate',
        maxLength: 10,
        isRequired: true,
        validate: handleRateValidation,
        type: 'percentage',
        formatter: { shouldPercentage: true, numericOnly: true },
      },
      {
        label: 'Maximum Rate',
        name: 'pioMaxRate',
        maxLength: 10,
        isRequired: true,
        type: 'percentage',
        validate: value => handleValidation(value),
        formatter: { shouldPercentage: true, numericOnly: true },
      },
    ],
    [handleRateValidation],
  );

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          {PiosFormFields?.map(
            ({
              label,
              name,
              readOnly,
              maxLength,
              isRequired,
              type,
              validate,
            }) => {
              const commonProps = {
                control,
                required: isRequired,
                rules: {
                  required: isRequired ? fieldError : null,
                },
              };

              if (type === 'percentage') {
                return (
                  <Input
                    key={name}
                    name={name}
                    label={label}
                    control={control}
                    maxLength={maxLength}
                    required={isRequired}
                    formatter={{ shouldPercentage: true, numericOnly: true }}
                    rules={{
                      required: fieldError,
                      validate,
                    }}
                  />
                );
              }

              return (
                <Input
                  key={name}
                  name={name}
                  label={label}
                  isReadOnly={readOnly}
                  maxLength={maxLength}
                  {...commonProps}
                />
              );
            },
          )}
          <Checkbox control={control} label="Selectable" name="pioSelectable" />
          <Select
            control={control}
            label="Status"
            name="status"
            options={statusOptions}
          />
          {hasPioId ? (
            <Input
              control={control}
              label="Last Updated"
              name="pioLastUpdatedOn"
              isReadOnly
            />
          ) : null}
          <FormFooterActions
            linkProps={[{ path: redirectUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PioForm;
