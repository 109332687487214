import { useEffect, useState } from 'react';

import { TableDashboard } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';
import useCsvExportButton from '@hooks/useCsvExportButton';

import WorkflowsTable from './components/WorkflowsTable';

const setLocalStorage = (response, fieldName, itemName) => {
  const { data } = response?.value || {};
  if (data?.length) {
    return localStorage.setItem(fieldName, data?.[0]?.[itemName]);
  }
};

const Workflows = () => {
  const { get, isPending } = useApiFetch();
  const [refresh, setRefresh] = useState(false);

  const {
    csvClickRef,
    csvData: { result: exportData = [] } = {},
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleDataToLocalStorage = async () => {
    const [
      recordKeepersResponse,
      custodiansResponse,
      carriersResponse,
      productsResponse,
      rkChannelsResponse,
    ] = await Promise.allSettled([
      get('recordkeepers/get'),
      get('custodians/get'),
      get('carriers/get'),
      get('products-and-pios/get'),
      get('rkchannels/get'),
    ]);

    setLocalStorage(recordKeepersResponse, 'recordkeeperID', 'recordkeeperID');
    setLocalStorage(custodiansResponse, 'custodianID', 'custodianID');
    setLocalStorage(carriersResponse, 'carrierID', 'carrierID');
    setLocalStorage(productsResponse, 'productID', 'productID');
    setLocalStorage(rkChannelsResponse, 'rkChannelID', 'userID');
  };

  useEffect(() => {
    handleDataToLocalStorage();
  }, []);

  const handleExportCsv = () => {
    getExport('workflows/export');
  };

  return (
    <TableDashboard
      pageTitle="Workflows"
      setRefresh={setRefresh}
      exportCsvProps={{
        fileName: 'WorkflowsList',
        csvClickRef,
        csvData: exportData,
        getCsvExport: handleExportCsv,
        loadingResponse,
      }}
    >
      <WorkflowsTable
        refresh={refresh}
        setRefresh={setRefresh}
        isRequesting={isPending}
      />
    </TableDashboard>
  );
};

export default Workflows;
