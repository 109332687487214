import { Container } from 'reactstrap';

import CarrierForm from '../CarrierForm/CarrierForm';

const defaultValues = {
  carrierName: '',
  extCarrierCode: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  endpointSetID: 1,
};

const AddCarrier = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Carrier Detail</h3>

    <CarrierForm carriers={defaultValues} redirectUrl="/carriers" />
  </Container>
);

export default AddCarrier;
