import React from 'react';

import * as classNames from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import { Controller } from 'react-hook-form';

const Checkbox = (props) => {
  const {
    name,
    control,
    rules,
    className = '',
    required = false,
    defaultChecked = false,
    label,
    disabled = false,
  } = props || {};

  const CheckboxClass = classNames({
    'checkbox-btn': true,
    disabled,
  });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      rules={rules}
      render={({
        field: { onChange: fieldOnChange, onBlur: fieldOnBlur, value },
        formState: { errors },
      }) => (
        <div className="form__form-group form__form-group-input-wrap--error-above">
          {label ? (
            <label className="form__form-group-label">
              {label}
              {required && <span className="text-danger"> *</span>}
            </label>
          ) : null}
          <div className="form__form-group-field pl-4 pb-2 mt-0">
            <label
              className={`${CheckboxClass} ${
                className ? ` checkbox-btn--${className}` : ''
              }`}
            >
              <input
                className="checkbox-btn__checkbox"
                type="checkbox"
                name={name}
                onChange={fieldOnChange}
                onBlur={fieldOnBlur}
                checked={!!value}
                disabled={disabled}
              />
              <span className="checkbox-btn__checkbox-custom">
                <CheckIcon />
              </span>
            </label>
            {errors?.[name] && (
              <span className="form__form-group-error">
                {errors?.[name]?.message}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default Checkbox;
