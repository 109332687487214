import React, { useCallback, useEffect, useState } from 'react';

import { Container } from 'reactstrap';

import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const additionalFormFields = [
  { label: 'withdrawal Amount', name: 'withdrawalAmount' },
  { label: 'withdrawal Percent', name: 'withdrawalPercent' },
  { label: 'withdrawal Type', name: 'withdrawalType' },
  { label: 'withdrawal Method', name: 'withdrawalMethod' },
  { label: 'Terminate Policy', name: 'terminatePolicy' },
  { label: 'Net Amount', name: 'netAmount' },
  { label: 'Fee Amount', name: 'feeAmount' },
  { label: 'MVA Amount', name: 'mvaAmount' },
];

const WorkflowTypeID1008 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const { get, isPending } = useApiFetch();

  const [fetchData, setFetchData] = useState({});

  const getData = useCallback(async () => {
    const data = await get(`workflows/workflowdetail1008/${workflowId}`);
    setFetchData(data);
  }, [workflowId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Premium Withdrawal</h3>

      <FormSpinner requesting={isPending} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={fetchData}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1008;
