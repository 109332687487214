import { useCallback, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const buildQueryParams = (params) => {
    const queryEntries = Object.entries(params) || [];
    if (queryEntries?.length) {
      return queryEntries
        .filter(([_, value]) => value)
        .map(
          ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        )
        .join('&');
    }
    return '';
  };

  const getQueryParams = useCallback(
    (queryParams) => {
      const stringTypeParams = typeof queryParams === 'string';
      if (stringTypeParams) {
        return query.get(queryParams);
      }

      return queryParams?.reduce((acc, param) => {
        acc[param] = query.get(param) || '';
        return acc;
      }, {});
    },
    [query],
  );

  return {
    getQueryParams,
    buildQueryParams,
  };
};

export default useQueryParams;
