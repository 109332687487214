import React from 'react';

import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import RKChannelsTable from './components/RKChannelsTable';

const RKChannels = () => (
  <Container>
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="mb-3">RK Channels</h3>
      <Link to="/rk_channels/add" className="btn btn-primary">Add</Link>
    </div>

    <RKChannelsTable />
  </Container>
  );

export default RKChannels;
