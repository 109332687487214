import {
 useEffect, useState, useMemo, useCallback,
} from 'react';

import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { Spinner, Table, TableDashboard } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

const queryPath = ['page', 'pageSize'];

const Carriers = () => {
  const [refresh, setRefresh] = useState(false);

  const { get, isPending } = useApiFetch();
  const { getQueryParams } = useQueryParams();

  const { page, pageSize: pageSizeQuery } = getQueryParams(queryPath);
  const [pageSize, setPageSize] = useState(pageSizeQuery || pagination.length);
  const [carriersData, setCarriersData] = useState();

  const { data = [], recordCount = 0 } = carriersData || {};
  const { offset, pageNumber } = getPagination(pageSize, page);

  const getCarriers = useCallback(async () => {
    const data = await get(`carriers/get?Offset=${offset}&limit=${pageSize}`);
    setCarriersData(data);
  }, [pageSize, offset]);

  useEffect(() => {
    getCarriers();
    setRefresh(false);
  }, [getCarriers, refresh]);

  const column = useMemo(
    () => [
      {
        id: 'carrierName',
        header: 'Carrier Name',
        accessorKey: 'carrierName',
      },
      {
        id: 'contactName',
        header: 'Contact Name',
        accessorFn: row => row.contactName || '-',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: row => row.contactPhone || '-',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: row => row.contactEmail || '-',
      },
      {
        id: 'editActions',
        header: '',
        cell: (tableRow) => {
          const { carrierID } = tableRow.row.original || {};
          const navigateToCarrierDetails = `/carriers/${carrierID}/edit?from=${pageNumber}${
            pageSize ? `&pageSize=${pageSize}` : ''
          }`;
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToCarrierDetails}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [pageNumber, pageSize],
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <TableDashboard
      pageTitle="Carriers"
      setRefresh={setRefresh}
      navigatePath="/carriers/add"
    >
      <Spinner requesting={isPending}>
        <Table columns={column} data={data} pageProps={pageProps} />
      </Spinner>
    </TableDashboard>
  );
};

export default Carriers;
