import { useEffect, useMemo } from 'react';

import moment from 'moment';
import Button from 'reactstrap/lib/Button';

import { ExportToCsv, TableSearchInput, DateRangePicker } from '@atoms';
import { dateRangeOptions } from '@config/conf';
import useCsvExportButton from '@hooks/useCsvExportButton';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';

const commonClassName = 'text-light table__search-input mb-0';
const dateRangeInputData = [
  {
    name: 'startDate',
    placeholder: 'Effective Start Date (MM/DD/YYYY)',
  },
  {
    name: 'endDate',
    placeholder: 'Effective End Date (MM/DD/YYYY)',
  },
];

const CashLedgerTableSearch = ({ setSubmitSearch, form }) => {
  const { control, watch, setValue } = form;

  const currentDateAndTime = moment();
  const { buildQueryParams } = useQueryParams();

  const { dateRangeInput, startDate, endDate } = watch();
  const shouldReset = dateRangeInput || startDate || endDate;
  const isCustomRange = dateRangeInput === 'CustomRange';

  const {
    csvClickRef,
    csvData: { response: exportData = [] },
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleCsvExport = () => {
    const queryParams = buildQueryParams({
      dateRangeRequested: dateRangeInput,
      effectiveStartDate: startDate,
      effectiveEndDate: endDate,
    });

    getExport(`operations/cashledger/exportCashLedgerTable?${queryParams}`);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setValue('dateRangeInput', 'Today');
    setValue('startDate', null);
    setValue('endDate', null);
  };

  useEffect(() => {
    if (!dateRangeInput) {
      setValue('dateRangeInput', 'Today');
    } else if (dateRangeInput !== 'CustomRange') {
      setValue('startDate', '');
      setValue('endDate', '');
    }
  }, [dateRangeInput]);

  const csvFileName = useMemo(() => {
    const csvDate = formatDate(currentDateAndTime, 'YYYYMMDD_HHmmss');
    return `CashLedger_${csvDate}.csv`;
  }, [currentDateAndTime]);

  const handleClick = () => {
    setSubmitSearch(true);
  };

  return (
    <div className="table__search mb-0 flex align-items-center form-inline w-100">
      <TableSearchInput
        control={control}
        name="dateRangeInput"
        inputType="select"
        placeholder="Date Range"
        selectOptions={dateRangeOptions}
      />
      {isCustomRange
        && dateRangeInputData.map(({ name, placeholder }) => (
          <DateRangePicker
            key={name}
            control={control}
            name={name}
            placeholder={placeholder}
            datePickerVariant="table"
          />
        ))}
      <Button
        color="blue"
        className={commonClassName}
        onClick={handleClick}
      >
        Filter
      </Button>
      <ExportToCsv
        csvData={exportData}
        csvRef={csvClickRef}
        fileName={csvFileName}
        getCsvExport={handleCsvExport}
        loadingResponse={loadingResponse}
        colorVariant="blue"
        className={commonClassName}
      />
      {shouldReset && (
        <Button
          type="reset"
          color="blue"
          className={commonClassName}
          onClick={handleClear}
        >
          Clear
        </Button>
      )}
    </div>
  );
};

export default CashLedgerTableSearch;
