import { useCallback, useContext, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { useHistory, useLocation } from 'react-router-dom';
import { Table as ReactTable, Card, CardBody } from 'reactstrap';

import { msgs, pagination } from '@config/conf';
import ReactTablePagination from '@containers/Users/components/ReactTablePagination';
import { connectTheme } from '@contexts/Context';

import EditableCell from './EditableCell';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHead from './TableHead';

const Table = (props) => {
  const {
    columns = [],
    data = [],
    renderNestedTable,
    pageProps,
    hasNestedTable,
    noDataMessage = msgs.noRows,
    footer,
    hidePagination = false,
    noPadding = false,
    noBottomBorder = false,
    isEditable = false,
    editableProps,
  } = props || {};

  const {
    total,
    pageSize,
    setPageSize,
    pageNumber,
    pageName = 'page',
    fromPage,
  } = pageProps || {};

  const history = useHistory();
  const { pathname = '', search } = useLocation();
  const { isLight } = useContext(connectTheme);
  const [sorting, setSorting] = useState({ id: '', desc: undefined });
  const themeClass = isLight ? 'light-table' : 'dark-table';

  const editableCell = useCallback(
    props => <EditableCell {...props} {...editableProps} />,
    [editableProps],
  );

  const { getHeaderGroups, getRowModel, getAllLeafColumns } = useReactTable({
    columns,
    data,
    defaultColumn: isEditable && {
      cell: editableCell,
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  const renderPagination = useCallback(() => {
    const currentUrlParams = new URLSearchParams(search);
    const pagesCount = Math.ceil(total / pageSize);
    const offset = (pageNumber - 1) * pageSize;

    const options = [];
    for (let index = 0; index < pagesCount; index += 1) {
      options.push(index + 1);
    }

    const goToPage = (page, pageSizeValue) => {
      const pageSizePath = `&pageSize=${pageSizeValue || 20}`;
      const fromPagePath = fromPage ? `from=${fromPage}&` : '';

      const sortParams = Array.from(currentUrlParams.entries())
        .filter(([key]) => key.startsWith('sort'))
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      history.push(
        `${pathname}?${fromPagePath}${pageName}=${page}${pageSizePath}${
          sortParams ? `&${sortParams}` : ''
        }`,
      );
    };

    return (
      <ReactTablePagination
        dataLength={total}
        page={data}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageIndex={offset}
        pageOptions={options}
        pageNumber={pageNumber}
        gotoPage={goToPage}
        canPreviousPage={pageNumber !== 1}
        canNextPage={pageNumber !== options.length}
        previousPage={() => goToPage(pageNumber - 1, pageSize)}
        nextPage={() => goToPage(pageNumber + 1, pageSize)}
        manualPageSize={pagination.list}
      />
    );
  }, [data, pageNumber, pageSize, search, setPageSize]);

  const renderTable = useCallback(() => {
    const className = hasNestedTable
      ? 'acc--table table--bordered'
      : 'table table--bordered';

    return (
      <ReactTable
        responsive
        className={`${className} ${hasNestedTable ? themeClass : ''}`}
      >
        <TableHead
          headerGroups={getHeaderGroups}
          sortingState={sorting}
          setSorting={setSorting}
          pageSize={pageSize}
        />
        <TableBody
          getAllLeafColumns={getAllLeafColumns}
          rows={getRowModel()?.rows}
          renderNestedTable={renderNestedTable}
          noDataMessage={noDataMessage}
          noBottomBorder={noBottomBorder}
          isEditable={isEditable}
        />
        {footer ? (
          <TableFooter colspan={columns.length}>{footer}</TableFooter>
        ) : null}
      </ReactTable>
    );
  }, [
    hasNestedTable,
    sorting,
    themeClass,
    renderNestedTable,
    footer,
    isEditable,
  ]);

  return hasNestedTable ? (
    renderTable()
  ) : (
    <Card>
      <CardBody className={noPadding ? 'p-0' : ''}>
        {renderTable()}
        {hidePagination ? null : renderPagination()}
      </CardBody>
    </Card>
  );
};

export default Table;
