import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Spinner } from '@atoms/index';
import useApiFetch from '@hooks/useApiFetch';

import UpdateAnnuityAllocations from './UpdateAnnuityAllocations';

const UpdateAnnuityAllocationsWrapper = () => {
  const {
 get, post, isPending, errorMessage,
} = useApiFetch();
  const [productInvestmentOptions, setProductInvestmentOptions] = useState({});

  const allocationData = JSON.parse(sessionStorage.getItem('allocationData'));
  const reAccountNumber = sessionStorage.getItem('reAccountNumber');
  const windowLocationPath = sessionStorage.getItem('window.location.pathname');
  const annuityAuthToken = sessionStorage.getItem('annuityAuthToken');

  const isAnnuitantSystemUser = !windowLocationPath.includes('GenericUser');

  const getNavigateBackUrl = () => {
    if (windowLocationPath.includes('GenericUser')) {
      return `${windowLocationPath}`;
    }
    return `${windowLocationPath}?token=${annuityAuthToken}`;
  };

  const fetchProductInvestmentOptions = useCallback(async () => {
    const response = await get(
      `reaccounts/getPlanProductPios/${reAccountNumber}`,
    );
    setProductInvestmentOptions(response);
  }, [reAccountNumber]);

  useEffect(() => {
    fetchProductInvestmentOptions();
  }, [fetchProductInvestmentOptions]);

  const handleUpdate = (productInvestmentOptionAllocations) => {
    const navigateBack = getNavigateBackUrl();

    const updatedAllocations = productInvestmentOptionAllocations
      .filter(({ updatedRate }) => updatedRate > 0)
      .map(({ updatedRate, pioid, productId }) => ({
        pioId: pioid,
        productId,
        allocationRate: updatedRate / 100,
      }));

    post(
      `reaccounts/adupdatePIO/${reAccountNumber}/${isAnnuitantSystemUser}`,
      updatedAllocations,
      { redirectUrl: navigateBack },
    );
  };

  const finalAllocationsWithPios = useMemo(() => {
    const cleanPios = (productInvestmentOptions?.data?.data)?.map(p => ({
      ...p,
      allocationRate: 0,
      updatedRate: 0,
    })) || [];

    const allocatedPios = (allocationData)?.reduce((acc, item) => {
      acc[item?.pioid] = {
        ...item,
        allocationRate: (item?.allocationRate * 100).toFixed(0),
        updatedRate: 0,
      };
      return acc;
    }, {});

    return cleanPios?.map(p => ({
      ...p,
      ...(allocatedPios[p.pioid] || {}),
    }));
  }, [allocationData, productInvestmentOptions?.data?.data]);

  return (
    <>
      {!productInvestmentOptions?.data ? (
        <div className="pt-5">
          <h4 className="text-center text-danger">
            <span>{errorMessage}</span>
          </h4>
        </div>
      ) : (
        <Spinner requesting={isPending}>
          <UpdateAnnuityAllocations
            handleUpdate={handleUpdate}
            combinedPios={finalAllocationsWithPios}
          />
        </Spinner>
      )}
    </>
  );
};

export default UpdateAnnuityAllocationsWrapper;
