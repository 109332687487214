import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import CarrierForm from '../CarrierForm/CarrierForm';

const queryPath = ['from', 'pageSize'];

const EditCarrier = (props) => {
  const {
    match: {
      params: { carrierId },
    },
  } = props;
  const { get, isPending } = useApiFetch();
  const { getQueryParams } = useQueryParams();

  const { from: page, pageSize } = getQueryParams(queryPath);

  const [carriersData, setCarriersData] = useState({});

  const getSingleCarrier = useCallback(async () => {
    const data = await get(`carriers/${carrierId}`);
    setCarriersData(data);
  }, [carrierId]);

  useEffect(() => {
    getSingleCarrier();
  }, [getSingleCarrier]);

  const redirectUrl = useMemo(
    () => `/carriers?page=${page}${pageSize ? `&pageSize=${pageSize}` : ''}`,
    [pageSize, page],
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Carrier Details</h3>

      <FormSpinner requesting={isPending} />
      <CarrierForm carriers={carriersData} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditCarrier;
