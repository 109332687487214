import Input from '@atoms/Input';

const EditableCell = ({
  row,
  column,
  table,
  onBlur,
  onChange,
  maxLength,
  control,
  tableName,
  alignRight = false,
  alignMiddle = false,
  numericOnly = false,
  shouldPercentage = false,
  disableFn,
}) => {
  const { index } = row;
  const { id } = column;

  const shouldDisable = disableFn?.({ row, column });
  const handleOnBlur = () => {
    onBlur?.(index, id, table);
  };

  const inputCellAlignment = alignRight
    ? 'column-end'
    : alignMiddle
    ? 'column-center'
    : 'column-start';

  return (
    <div className="editable-cell w-100 flex align-items-center pr-2" id={id}>
      {shouldDisable ? (
        <span className={inputCellAlignment}>{row.original[id]}</span>
      ) : (
        <Input
          name={`${tableName}.${index}.${id}`}
          control={control}
          formatter={{ numericOnly, allowDecimal: true }}
          onBlur={handleOnBlur}
          onChange={onChange}
          className={`text-dark ${inputCellAlignment}`}
          maxLength={maxLength}
          wrapperClassName="w-full"
        />
      )}
      {shouldPercentage ? <span className="percentage-sign">%</span> : null}
    </div>
  );
};

export default EditableCell;
