import { useMemo } from 'react';

import {
 Card, CardBody, Input,
} from 'reactstrap';

import { TableSearchInput } from '@atoms';

const commonClassName = 'btn-w text-light table__search table__search-input mb-0';

const UsersTableSearch = ({ form, setSubmitSearch }) => {
  const { control, setValue, watch } = form;
  const { searchInput } = watch();

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setValue('searchInput', '');
  };

  const handleClick = () => {
    setSubmitSearch(true);
  };

  const buttonProps = useMemo(
    () => [
      {
        type: 'submit',
        className: `btn-blue ${commonClassName}`,
        value: 'Filter',
        onClick: handleClick,
      },
      ...(searchInput
        ? [
            {
              type: 'reset',
              className: commonClassName,
              value: 'Clear',
              onClick: handleClear,
            },
          ]
        : []),
    ],
    [searchInput],
  );

  return (
    <Card className="pb-2">
      <CardBody className="py-2 bg-green table__search mb-0">
        <div className="table__search mb-0 flex align-items-center form-inline w-100">
          <TableSearchInput
            name="searchInput"
            control={control}
            className="w-100"
            placeholder="User Name or Login Name"
            maxLength={50}
          />
          {buttonProps?.map(props => (
            <Input key={props?.value} {...props} />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default UsersTableSearch;
