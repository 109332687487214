import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import {
  sanitizeValue,
  splitStringyNumber,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import EditCashLedgerForm from './components/EditCashLedgerForm';

const queryPath = [
  'from',
  'dateRangeInput',
  'effectiveStartDate',
  'effectiveEndDate',
  'pageSize',
];

const EditCashLedger = ({
  match: {
    params: { id },
  },
}) => {
  const { getQueryParams, buildQueryParams } = useQueryParams();
  const { get, isPending, put } = useApiFetch();

  const [cashLedger, setCashLedger] = useState({});

  const { from: page, ...restParams } = getQueryParams(queryPath);

  const getSingleCashLedger = useCallback(async () => {
    const response = await get(`operations/cashledger/dailysummary/${id}`);
    setCashLedger(response?.response);
  }, [id]);

  useEffect(() => {
    getSingleCashLedger();
  }, [getSingleCashLedger]);

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });

    return `/cash-ledger?${queryParams}`;
  }, [page, restParams]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const payloadValues = {
      ...values,
      adjustmentAmount: Number(splitStringyNumber(values.adjustmentAmount)),
    };

    put(`operations/cashledger/dailysummary/${id}`, payloadValues, {
      redirectUrl,
    });
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Cash Ledger Summary Detail</h3>

      <FormSpinner requesting={isPending} />
      <EditCashLedgerForm
        data={cashLedger}
        onSubmit={handleForm}
        redirectUrl={redirectUrl}
      />
    </Container>
  );
};

export default EditCashLedger;
