import React, { useState, useEffect, useCallback } from 'react';

import { Container } from 'reactstrap';

import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const additionalFormFields = [
  { label: 'First Name', name: 'firstName' },
  { label: 'Middle Name', name: 'middleName' },
  { label: 'Last Name', name: 'lastName' },
  { label: 'Email Address', name: 'emailAddress' },
  { label: 'Primary Phone', name: 'homePhone' },
  { label: 'Mobile Phone', name: 'mobilePhone' },
];

const WorkflowTypeID1001 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const { get, isPending } = useApiFetch();

  const [fetchData, setFetchData] = useState({});

  const getData = useCallback(async () => {
    const data = await get(`workflows/workflowdetail1001/${workflowId}`);
    setFetchData(data);
  }, [workflowId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant Information Update</h3>

      <FormSpinner requesting={isPending} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={fetchData}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1001;
