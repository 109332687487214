import React from 'react';

import { Container } from 'reactstrap';

import ProductsForm from '../ProductsForm/ProductsForm';

const defaultValues = {
  carrierID: localStorage.getItem('carrierID'),
  carrierProductCode: '',
  carrierProductCusip: '',
  productName: '',
  procductTypeID: '',
  minInitialPurchase: '',
  minPolicyIssueAge: '',
  maxPolicyIssueAge: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '',
  notes: '',
  annuityFees: '0.00%',
  productIdentifier: '',
  annualBonus: '0.00%',
  incomeAcceleratedCredit: '0.00%',
};

const AddProduct = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Product Detail</h3>
    <ProductsForm products={defaultValues} redirectUrl="/products" />
  </Container>
);

export default AddProduct;
