import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import FormSpinner from '@containers/FormSpinner/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import EditPandWsForm from './component/EditPandWsForm';

const queryPath = ['page', 'pwSearch', 'pwStatus', 'pwCustodian'];

const EditPandWs = ({
  match: {
    params: { id },
  },
}) => {
  const { get, isPending } = useApiFetch();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const [data, setData] = useState({});

  const { page = 1, ...restParams } = getQueryParams(queryPath);

  const getPurchaseAndWithdrawals = useCallback(async () => {
    const response = await get(`operations/pandw/detail/${id}`);
    setData(response?.data);
  }, [id]);

  useEffect(() => {
    getPurchaseAndWithdrawals();
  }, [getPurchaseAndWithdrawals]);

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({ from: page, ...restParams });

    return `/purchases-and-withdrawals?${queryParams}`;
  }, [page, restParams]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Purchases &amp; Withdrawals Detail</h3>
      <FormSpinner requesting={isPending} />
      <EditPandWsForm data={data} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditPandWs;
