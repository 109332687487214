import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Badge from 'reactstrap/lib/Badge';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';

import { TableDashboard, Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import RecordKeeperTableSearch from './RecordKeeperTableSearch';

const queryParams = [
  'search',
  'pageSize',
  'page',
  'sortRecordkeeperName',
  'sortRKChannel',
  'sortStatus',
];

const RecordKeepers = () => {
  const { get, isPending } = useApiFetch();
  const history = useHistory();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    search,
    pageSize: pageSizePath,
    page,
    sortRecordkeeperName,
    sortRKChannel,
    sortStatus,
  } = getQueryParams(queryParams);

  const form = useForm({
    defaultValues: {
      searchInput: search,
    },
  });

  const { getValues } = form;
  const { searchInput } = getValues();

  const [submitSearch, setSubmitSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(pageSizePath || pagination.length);
  const [recordKeepers, setRecordKeepers] = useState({});

  const { offset, pageNumber } = getPagination(pageSize, page);
  const { data = [], recordCount = 0 } = recordKeepers || {};

  const orderBy = useMemo(() => {
    if (sortRecordkeeperName) return `Recordkeepers.RecordkeeperName ${sortRecordkeeperName.toUpperCase()}`;
    if (sortRKChannel) return `Users.UserName ${sortRKChannel.toUpperCase()}, Recordkeepers.RecordkeeperName ASC`;
    if (sortStatus) return `Recordkeepers.Status ${sortStatus.toUpperCase()}, Recordkeepers.RecordkeeperName ASC`;
    return '';
  }, [sortRKChannel, sortRecordkeeperName, sortStatus]);

  const getRecordKeepers = useCallback(async () => {
    const queryParams = buildQueryParams({
      Offset: offset,
      limit: pageSize,
      OrderBy: orderBy,
      RecordkeeperName: searchInput,
    });
    const data = await get(
      `recordkeepers/get?${queryParams}`,
    );
    setRecordKeepers(data);
  }, [orderBy, offset, pageSize, refresh, submitSearch]);

  const navigateToEditPage = useCallback(
    (id = '') => {
      const query = buildQueryParams({
        sortRecordkeeperName,
        sortRKChannel,
        search: searchInput,
        sortStatus,
        pageSize,
      });
      const params = id
        ? `/recordkeepers/${id}/edit?from=${pageNumber}`
        : '?page=1';
      return `${params}&${query}`;
    },
    [
      pageNumber,
      pageSize,
      sortRKChannel,
      sortRecordkeeperName,
      sortStatus,
      searchInput,
    ],
  );

  useEffect(() => {
    if (submitSearch) {
      const pageReload = navigateToEditPage();
      history.push(pageReload);
    } else {
      getRecordKeepers();
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [getRecordKeepers, refresh, submitSearch]);

  const column = useMemo(
    () => [
      {
        id: 'recordkeeperName',
        header: 'Recordkeeper Name',
        accessorFn: row => row?.recordkeeperName || '-',
        enableSorting: true,
        size: 275,
      },
      {
        id: 'rKChannel',
        header: 'RK Channel',
        accessorFn: row => row?.userName || '-',
        enableSorting: true,
        size: 200,
      },
      {
        id: 'contactName',
        header: 'Contact Name',
        accessorFn: row => row?.contactName || '-',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: row => row?.contactPhone || '-',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: row => row?.contactEmail || '-',
        size: 200,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: row => row?.status || '-',
        enableSorting: true,
      },
      {
        id: 'editActions',
        header: '',
        cell: (tableRow) => {
          const { recordkeeperID } = tableRow.row.original || {};
          const editRecordkeeperPage = navigateToEditPage(recordkeeperID);
          return (
            <div className="column-end pr-2 my-1">
              <Link to={editRecordkeeperPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigateToEditPage],
  );

  const pageProps = useMemo(
    () => ({
      total: recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [pageNumber, pageSize, recordCount],
  );

  return (
    <TableDashboard
      pageTitle="Recordkeepers"
      setRefresh={setRefresh}
      navigatePath="/recordkeepers/add"
    >
      <Spinner requesting={isPending}>
        <Card className="pb-2">
          <CardBody className="py-2 bg-green">
            <RecordKeeperTableSearch
              form={form}
              setSubmitSearch={setSubmitSearch}
            />
          </CardBody>
        </Card>
        <Table columns={column} data={data} pageProps={pageProps} />
      </Spinner>
    </TableDashboard>
  );
};

export default RecordKeepers;
