import { useCallback, useEffect } from 'react';

import { flexRender } from '@tanstack/react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import { useLocation, useHistory } from 'react-router-dom';

import useQueryParams from '@hooks/useQueryParams';
import { getCapitalizeFirstLetter } from '@lib/string';

const SortingIcon = ({ isDesc, id, sortingId }) => {
  const icon = isDesc ? <SortDescendingIcon /> : <SortAscendingIcon />;
  return id === sortingId ? icon : <SortIcon />;
};

const TableHead = (props) => {
  const {
 headerGroups, sortingState, setSorting, pageSize,
} = props || {};
  const location = useLocation();
  const history = useHistory();
  const { getQueryParams } = useQueryParams();
  const pathName = location.pathname;
  const { id: sortingId, desc } = sortingState || {};
  const groupHeaders = headerGroups();

  useEffect(() => {
    const sortId = groupHeaders[0]?.headers.find(data => getQueryParams(`sort${getCapitalizeFirstLetter(data.id)}`))?.id;
    const sort = getQueryParams(`sort${getCapitalizeFirstLetter(sortId)}`);
    if (sortId && sort) {
      setSorting({ id: sortId, desc: sort === 'desc' });
    }
  }, []);

  const updateUrlParams = useCallback(
    (newParams) => {
      const params = new URLSearchParams();
      params.set('page', '1');
      Object.entries(newParams).forEach(([key, value]) => {
        params.set(key, value);
      });
      params.set('pageSize', pageSize.toString());
      history.push(`${pathName}?${params.toString()}`);
    },
    [history, pageSize, pathName],
  );

  const onToggleSortBy = useCallback(
    (columnId, canSort) => {
      if (canSort) {
        const capitalizedHeader = `sort${getCapitalizeFirstLetter(columnId)}`;
        const isSortDesc = sortingId === columnId ? !desc : false;
        setSorting({ id: columnId, desc: isSortDesc });
        updateUrlParams({ [capitalizedHeader]: isSortDesc ? 'desc' : 'asc' });
      }
    },
    [desc, setSorting, sortingId, updateUrlParams],
  );

  if (groupHeaders?.length) {
    return (
      <thead>
        {groupHeaders?.map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup?.headers.map((header) => {
              const { getCanSort, id, columnDef } = header.column;
              const canSort = getCanSort();

              const isSortingEnabled = columnDef?.enableSorting && canSort;
              const {
 size, minWidth, alignRight, alignCenter,
} = columnDef || {};

              return (
                <th
                  key={header.id}
                  style={{ width: size, minWidth }}
                  onClick={() => onToggleSortBy(id, isSortingEnabled)}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={`${
                        isSortingEnabled ? 'pointer mr-2' : ''
                      } flex ${
                        alignRight
                          ? 'justify-content-end'
                          : alignCenter
                          ? 'justify-content-center'
                          : 'justify-content-start'
                      }`}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}

                      {isSortingEnabled ? (
                        <span className="react-table__column-header sortable ml-2">
                          <SortingIcon
                            isDesc={desc}
                            id={id}
                            sortingId={sortingId}
                          />
                        </span>
                      ) : null}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    );
  }
};

export default TableHead;
