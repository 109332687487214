import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';

import ExportToCsv from '@atoms/ExportToCsv/ExportToCsv';

const HeaderActions = ({
  setRefresh,
  navigateLabel = 'Add',
  navigatePath = '',
  additionalChild,
  exportCsvProps,
}) => {
  const {
 fileName, csvClickRef, csvData, getCsvExport, loadingResponse,
} = exportCsvProps || {};

  const renderRefreshButton = () => {
    const isRefreshFunctionValid = typeof setRefresh === 'function';
    return isRefreshFunctionValid ? (
      <Button key="refresh" color="success" onClick={() => setRefresh(true)}>
        Refresh
      </Button>
    ) : null;
  };

  const renderNavigationLink = () => {
    const isNavigationPathValid = navigateLabel && navigatePath;
    return isNavigationPathValid ? (
      <Link key="navigate" to={navigatePath} className="btn btn-primary">
        {navigateLabel}
      </Link>
    ) : null;
  };

  const renderCsvExport = () => {
    const isCsvExportFunctionValid = typeof getCsvExport === 'function';
    return isCsvExportFunctionValid ? (
      <ExportToCsv
        key="csvExport"
        csvData={csvData}
        fileName={fileName}
        getCsvExport={getCsvExport}
        loadingResponse={loadingResponse}
        csvRef={csvClickRef}
      />
    ) : null;
  };

  const actions = [
    renderRefreshButton(),
    renderNavigationLink(),
    renderCsvExport(),
    additionalChild ? additionalChild?.() : null,
  ];

  return <div>{actions.filter(Boolean)}</div>;
};

const TableDashboard = ({
  pageTitle = '',
  className = '',
  children,
  ...props
}) => (
  <Container>
    <div
      className={`flex justify-content-between align-items-center ${className}`}
    >
      <h3 className="mb-2 text-green">{pageTitle}</h3>
      <HeaderActions {...props} />
    </div>
    {children}
  </Container>
);

export default TableDashboard;
