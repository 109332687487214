import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { Container } from 'reactstrap';

import { sanitizeValue } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';

import PlanProductForm from '../components/PlanProductForm';

const queryPath = ['from', 'search', 'sortPlanName', 'sortStatus', 'pageSize'];

const EditPlanProduct = (props) => {
  const editPlanProductForm = useForm({
    mode: 'onChange',
  });

  const {
    match: {
      params: { planId, productId: planProductId },
    },
  } = props;

  const { get, isPending, put } = useApiFetch();
  const [planProduct, setPlanProduct] = useState({});
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: pageNumber, ...restParams } = getQueryParams(queryPath);

  const getPlanProduct = useCallback(async () => {
    const response = await get(`plans/planproducts/${planProductId}`);
    setPlanProduct(response);
  }, [planProductId]);

  useEffect(() => {
    getPlanProduct();
  }, [getPlanProduct]);

  const navigateToPlans = useMemo(() => {
    const params = buildQueryParams({
      page: pageNumber,
      id: planId,
      ...restParams,
    });
    return `/plans?${params}`;
  }, [pageNumber, planId, restParams]);

  const handleEditForm = useCallback(
    (values) => {
      const {
        startDate,
        carrierPlanIdentifier,
        annualBonus,
        carrierID,
        incomeAcceleratedCredit,
        productIdentifier,
        status,
      } = values;

      const payloadValues = {
        annualBonus,
        carrierID,
        incomeAcceleratedCredit,
        productIdentifier,
        status,
        startDate: formatDate(startDate, 'YYYY-MM-DD'),
        carrierPlanIdentifier: sanitizeValue(carrierPlanIdentifier),
      };
      put(`plans/${planId}/planproducts/${planProductId}`, payloadValues, {
        redirectUrl: navigateToPlans,
      });
    },
    [planId, planProductId],
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Product Detail</h3>
      <PlanProductForm
        form={editPlanProductForm}
        data={planProduct}
        planProductId={planProductId}
        planId={planId}
        onSubmit={handleEditForm}
        loadingResponse={isPending}
      />
    </Container>
  );
};

export default EditPlanProduct;
