import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody } from 'reactstrap';

import { Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { formatCurrency, getPagination } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import PlansTableSearch from '../components/PlansTableSearch';

const IMMEDIATE_ANNUITY = 'Immediate Annuity';

const queryParams = [
  'page',
  'sortPlanName',
  'sortStatus',
  'search',
  'pageSize',
];

const PlanTable = ({
 isLoading, refresh, setRefresh, plansTableData,
}) => {
  const { get, isPending } = useApiFetch();
  const { getQueryParams, buildQueryParams } = useQueryParams();
  const history = useHistory();

  const {
    page,
    sortPlanName,
    sortStatus,
    search: filter,
    pageSize: pageSizePath,
  } = getQueryParams(queryParams);

  const form = useForm({
    defaultValues: {
      searchInput: filter,
    },
  });

  const [plansData, setPlansData] = useState({});
  const [submitSearch, setSubmitSearch] = useState(false);
  const [pageSize, setPageSize] = useState(pageSizePath || pagination.length);

  const { offset, pageNumber } = getPagination(pageSize, page);
  const { custodians, productTypes, recordKeepers } = plansTableData || {};
  const { recordCount = 0, data: fetchPlanData = [] } = plansData || {};

  const { getValues } = form;
  const { searchInput } = getValues();

  const orderBy = useMemo(() => {
    if (sortPlanName) return `Plans.PlanName ${sortPlanName.toUpperCase()}, Products.ProductName ASC`;
    if (sortStatus) return `Plans.Status ${sortStatus.toUpperCase()}, Plans.PlanName ASC, Products.ProductName ASC`;
    return '';
  }, [sortPlanName, sortStatus]);

  const immediateAnnuity = useMemo(
    () => productTypes
        ?.filter(typeName => typeName?.productTypeName === IMMEDIATE_ANNUITY)
        ?.find(typeId => typeId?.productTypeID)?.productTypeID,
    [productTypes],
  );

  const navigatePath = useCallback(
    (id = '', childParams = '', path = 'plans') => {
      const query = buildQueryParams({
        search: searchInput.trimStart(),
        sortPlanName,
        sortStatus,
        pageSize,
      });

      const params = id
        ? `/${path}/${id}/${childParams}from=${pageNumber}`
        : '?page=1';

      return `${params}&${query}`;
    },
    [pageNumber, pageSize, searchInput, sortPlanName, sortStatus],
  );

  const getPlans = useCallback(async () => {
    const params = buildQueryParams({
      Limit: pageSize,
      Offset: offset,
      OrderBy: orderBy,
      search: searchInput,
    });

    const response = await get(
      `plans-and-products/get?${params}`,
    );
    setPlansData(response);
  }, [offset, orderBy, pageSize, submitSearch]);

  useEffect(() => {
    if (submitSearch) {
      const pageReload = navigatePath();
      history.push(pageReload);
    } else {
      getPlans();
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [submitSearch, getPlans, refresh]);

  const columns = [
    {
      id: 'expanded-chevron',
      header: '',
      size: 0,
      cell: (tableRow) => {
        const { expandedRowIds, row } = tableRow || {};
        const className = `lnr ${
          expandedRowIds?.includes(row.id)
            ? 'lnr-chevron-down'
            : 'lnr-chevron-right'
        }`;

        return (
          <div className="expanded_chevron">
            <i className={className} />
          </div>
        );
      },
    },
    {
      id: 'planName',
      header: 'Plan Name',
      accessorKey: 'planName',
      enableSorting: true,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'planStatus',
      enableSorting: true,
    },
    {
      id: 'recordkeeper',
      header: 'Recordkeeper',
      cell: (tableRow) => {
        const { recordkeeperID } = tableRow.row.original || {};
        const recordkeeperName = recordKeepers
          ?.filter(val => val?.recordkeeperID === recordkeeperID)
          ?.find(val => val?.recordkeeperName)?.recordkeeperName;
        return recordkeeperName || '-';
      },
    },
    {
      id: 'custodian',
      header: 'Custodian',
      cell: (tableRow) => {
        const { custodianID } = tableRow.row.original || {};
        const custodianName = custodians
          ?.filter(val => val?.custodianID === custodianID)
          ?.find(val => val?.custodianName)?.custodianName;
        return custodianName || '-';
      },
    },
    {
      id: 'actions',
      header: '',
      cell: (tableRow) => {
        const { planID } = tableRow?.row?.original || {};
        const planDetailsPage = navigatePath(planID, 'edit?');
        const addProductPage = navigatePath(planID, 'products/add?');
        return (
          <div className="flex justify-content-end pr-2 my-1">
            <Link className="mr-2" to={planDetailsPage}>
              <Badge className="btn-blue btn-badge align-middle">
                View / Edit
              </Badge>
            </Link>
            <Link to={addProductPage}>
              <Badge color="success">Add Product</Badge>
            </Link>
          </div>
        );
      },
    },
  ];

  const productTableColumns = useCallback(
    planId => [
      {
        id: 'productName',
        header: 'Product Name',
        accessorKey: 'productName',
      },
      {
        id: 'carrierProductCode',
        header: 'Product Code',
        accessorKey: 'carrierProductCode',
      },
      {
        id: 'productIdentifier',
        header: 'Product Identifier',
        accessorKey: 'productIdentifier',
      },
      {
        id: 'minInitialPurchase',
        header: 'Initial Purchase',
        accessorFn: row => formatCurrency(row?.minInitialPurchase),
      },
      {
        id: 'planProductStatus',
        header: 'Status',
        accessorKey: 'planProductStatus',
      },
      {
        id: 'view-edit-product',
        header: '',
        cell: (tableRow) => {
          const { cell } = tableRow || {};
          const { original } = cell?.row || {};
          const { planProductID, productID, productTypeID } = original || {};
          const isImmediateAnnuity = immediateAnnuity === productTypeID;
          const gotoEditPlanProduct = navigatePath(
            planId,
            `product/${planProductID}?`,
          );
          const gotoPioDetails = navigatePath(
            planProductID,
            `product/${productID}/pios?planId=${planId}&`,
            'plan',
          );
          const gotoFactorsDetails = navigatePath(
            planProductID,
            `product/${productID}/factors?planId=${planId}&`,
            'plan',
          );
          return (
            <div className="flex justify-content-end pr-2">
              <Link className="mr-2" to={gotoEditPlanProduct}>
                <Badge className="btn-blue btn-badge pointer">
                  View / Edit
                </Badge>
              </Link>
              <Link to={gotoPioDetails}>
                <Badge color="success" className="pointer">
                  PIOs
                </Badge>
              </Link>
              {isImmediateAnnuity ? (
                <Link className="ml-1" to={gotoFactorsDetails}>
                  <Badge color="success" className="pointer">
                    Factors
                  </Badge>
                </Link>
              ) : null}
            </div>
          );
        },
      },
    ],
    [immediateAnnuity, navigatePath],
  );

  const PlanSubTable = (subTableprops) => {
    const { row } = subTableprops || {};
    const { product = [], planID } = row?.original || {};
    const subColumns = productTableColumns(planID);
    return <Table columns={subColumns} data={product} hasNestedTable />;
  };

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <Spinner requesting={isPending || isLoading}>
      <Card className="pb-2">
        <CardBody className="py-2 bg-green">
          <PlansTableSearch form={form} setSubmitSearch={setSubmitSearch} />
        </CardBody>
      </Card>
      <Table
        columns={columns}
        data={fetchPlanData}
        renderNestedTable={PlanSubTable}
        pageProps={pageProps}
      />
    </Spinner>
  );
};

export default PlanTable;
