import { useCallback, useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Input, PasswordInput } from '@atoms';
import { msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useApiFetch from '@hooks/useApiFetch';

const LogInForm = () => {
  const { post, isPending } = useApiFetch();
  const { control, handleSubmit, reset } = useForm();
  const { setToken, setPayload } = useAuth();
  const history = useHistory();
  const [loginRes, setLoginRes] = useState('');

  const { loginName } = localStorage || {};
  const credentials = {
    loginName,
    password: '',
    remember: true,
  };

  const handleLogin = useCallback(async (values) => {
    localStorage.setItem('loginName', values.loginName);
    const response = await post('login', values, {
      loginAuth: true,
      successToast: false,
    });
    setLoginRes(response?.data?.token);
  }, []);

  const getData = useCallback(() => {
    reset(credentials);
    if (loginRes) {
      localStorage.setItem('authToken', loginRes);
      setToken(loginRes);
      setPayload(jwt_decode(loginRes));
      history.push('/workflows');
    }
  }, [reset, loginRes]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <form
      className="form form--horizontal login-form form_spacing"
      onSubmit={handleSubmit(handleLogin)}
    >
      <Input
        control={control}
        name="loginName"
        label="Login Name"
        rules={{ required: msgs.fieldError }}
        startIcon={<AccountOutlineIcon />}
      />
      <PasswordInput
        control={control}
        name="password"
        label="Password"
        type="password"
        wrapperClassName="mt-2"
        rules={{ required: msgs.fieldError }}
        startIcon={<KeyVariantIcon />}
      />

      <div className="account__btns pl-2">
        <button
          className="account__btn btn btn-primary"
          type="submit"
          disabled={isPending}
        >
          {isPending ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            'Sign In'
          )}
        </button>
      </div>
    </form>
  );
};

export default LogInForm;
