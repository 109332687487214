import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { Spinner } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import ViewAccountForm from './components/ViewAccountForm';

const ViewGenericUserAccountSummary = ({
  match: {
    params: { id: accountNumber },
  },
}) => {
  const { get, isPending, errorMessage } = useApiFetch();
  const [annuityData, setAnnuityData] = useState([]);
  const { getQueryParams } = useQueryParams();
  const genericAuthToken = getQueryParams('token');
  const history = useHistory();

  const currentTab = Number(sessionStorage.getItem('activeTab'));
  const [activeTab, setActiveTab] = useState(
    currentTab || (annuityData?.length > 1 ? 0 : 1),
  );

  const getAnnuitant = async () => {
    const response = await get(
      `reaccounts/annuitydetails/${accountNumber}/false`,
      { errorToast: false },
    );
    setAnnuityData(response?.data);
  };

  useEffect(() => {
    getAnnuitant();
    if (genericAuthToken) {
      sessionStorage.setItem('annuityAuthToken', decodeURI(genericAuthToken));
      sessionStorage.setItem('reAccountNumber', accountNumber);
      sessionStorage.setItem('activeTab', activeTab);
      sessionStorage.setItem('validToken', true);
      history.push(`/View/GenericUserAccount/Summary/${accountNumber}`);
    }
  }, []);

  return (
    <Spinner requesting={isPending}>
      <Container>
        <ViewAccountForm
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          data={annuityData}
          errorResponse={errorMessage}
        />
      </Container>
    </Spinner>
  );
};

export default ViewGenericUserAccountSummary;
