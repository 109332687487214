import { Container } from 'reactstrap';

import RecordKeepersForm from '../RecordKeepersForm/RecordKeepersForm';

const rkChannelId = localStorage.getItem('rkChannelID');

const defaultData = {
  rkChannelUserID: rkChannelId,
  recordkeeperName: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  status: 'Active',
  recordkeeperCode: '',
  extRecordkeeperIdentifier: '',
};

const AddRecordKeeper = () => (
  <Container className="form_wrapper">
    <h3 className="text-center mb-3">Recordkeeper Detail</h3>
    <RecordKeepersForm
      data={defaultData}
      redirectUrl="/recordkeepers"
    />
  </Container>
  );

export default AddRecordKeeper;
