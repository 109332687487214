import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Container } from 'reactstrap';

import useApiFetch from '@hooks/useApiFetch';

import NewTransactionWorkflowForm from './NewTransactionWorkflowForm';

const defaultValues = {
  withdrawalAmount: '0.00',
  withdrawalPercent: '0.00',
  amount: '0.00',
  withdrawalMethod: 'Gross',
  workflowType: 'Withdrawal',
  withdrawalType: 'Asset Transfer',
};

const NewTransactionWorkflow = (props) => {
  const {
    match: {
      params: { id: accountId },
    },
  } = props || {};

  const withdrawalForm = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { getValues, reset } = withdrawalForm || {};
  const { get, post } = useApiFetch();
  const [accountDetails, setAccountDetails] = useState({});

  const { reAccountNumber, policyNumber, policyStatus } = accountDetails || {};

  const getAccount = useCallback(async () => {
    const response = await get(`reaccounts/${accountId}`);
    setAccountDetails(response);
  }, [accountId]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  const createWithdrawalWorkflow = useCallback(async (values) => {
    await post('operations/withdrawal', values);
    reset({ ...defaultValues });
  }, []);

  const createPremiumPurchaseWorkflow = useCallback(async (values) => {
    await post('operations/premiumwithdrawal', values);
    reset({ ...defaultValues });
  }, []);

  const onSubmit = useCallback(() => {
    const {
      amount,
      workflowType,
      withdrawalAmount,
      withdrawalPercent,
      withdrawalType,
      withdrawalMethod,
    } = getValues();

    const isWithdrawal = workflowType === 'Withdrawal';
    const payloadValues = {
      ...(isWithdrawal
        ? {
            reAccountID: accountId,
            withdrawalAmount: Number(withdrawalAmount),
            withdrawalPercent: Number(withdrawalPercent),
            withdrawalType,
            withdrawalMethod,
          }
        : reAccountNumber
        ? {
            reAccountNumber: Number(reAccountNumber),
            amount: Number(amount),
          }
        : null),
    };

    isWithdrawal
      ? createWithdrawalWorkflow(payloadValues)
      : createPremiumPurchaseWorkflow(payloadValues);
  }, [
    accountId,
    createWithdrawalWorkflow,
    createPremiumPurchaseWorkflow,
    reAccountNumber,
    getValues,
  ]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">New Transaction Workflow</h3>
      <NewTransactionWorkflowForm
        form={withdrawalForm}
        accountId={accountId}
        onSubmit={onSubmit}
        policyNumber={policyNumber}
        policyStatus={policyStatus}
      />
    </Container>
  );
};

export default NewTransactionWorkflow;
