import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';

import {
 Input, Select, Checkbox, Modal, FormFooterActions,
} from '@atoms';
import { msgs, statusOptions } from '@config/conf';
import useApiFetch from '@hooks/useApiFetch';
import { emailPattern } from '@shared/helpers';

const userTypeOptions = [
  { value: 'Admin', label: 'Admin' },
  { value: 'RKChannel', label: 'RKChannel' },
  { value: 'MessagingChannel', label: 'MessagingChannel' },
  { value: 'Annuitant', label: 'Annuitant' },
];

const UserForm = ({
 data, redirectUrl, id, onSubmit,
}) => {
  const {
 control, reset, handleSubmit, watch,
} = useForm();
  const { put } = useApiFetch();

  const [modalOpen, setModalOpen] = useState(false);

  const onToggle = useCallback(() => setModalOpen(!modalOpen), [modalOpen]);

  useEffect(() => {
    reset(data);
  }, [data]);

  const userType = watch('userType');
  const userId = data?.userID;

  const commonProps = {
    control,
    required: true,
    rules: {
      required: msgs.fieldError,
    },
  };

  const onPasswordReset = useCallback(async () => {
    onToggle();
    await put(`users/${id}/passwordreset`);
  }, [id, onToggle]);

  const modalButtons = useMemo(
    () => [
      {
        name: 'Cancel',
        color: 'danger',
        onClick: onToggle,
      },
      {
        name: 'Save',
        color: 'primary',
        onClick: onPasswordReset,
      },
    ],
    [onPasswordReset, onToggle],
  );

  const buttonActions = {
    buttonProps: [
      userId ? { labelName: 'Reset Password', onClick: onToggle } : null,
      { onClick: handleSubmit(onSubmit) },
    ],
  };

  return (
    <div>
      <Modal
        isModalOpen={modalOpen}
        setIsModalOpen={setModalOpen}
        content="Are you sure you wish to reset this User’s password?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={modalButtons}
      />
      <Card className="container_width">
        <CardBody>
          <div className="form form--horizontal form_spacing">
            <Select
              {...commonProps}
              name="userType"
              label="User Type"
              disabled={userId}
              options={userTypeOptions}
            />
            <Input {...commonProps} name="userName" label="User Name" />
            <Input
              {...commonProps}
              label="Login Name"
              name="loginName"
              maxLength="50"
            />

            <Input
              control={control}
              name="userEmail"
              label="Email"
              required
              rules={{
                required: msgs.fieldError,
                pattern: {
                  value: emailPattern,
                  message: 'Email is invalid.',
                },
              }}
            />
            <Input control={control} name="userPhone" label="Phone" />
            <Select
              {...commonProps}
              name="status"
              label="Status"
              options={statusOptions}
            />

            {userId ? (
              <Checkbox
                control={control}
                name="lockedOut"
                label="Locked"
                disabled={!data?.lockedOut}
              />
            ) : null}

            {userId || userType === 'RKChannel' ? (
              <Checkbox
                control={control}
                name="ipxHosted"
                label="IPX Hosted"
                disabled={userId}
              />
            ) : null}
            <FormFooterActions
              linkProps={[{ path: redirectUrl }]}
              {...buttonActions}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default UserForm;
