import { useCallback, useEffect, useState } from 'react';

import { TableDashboard } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';

import PlanTable from './PlanProducts/PlanTable';

const Plans = () => {
  const { get, isPending } = useApiFetch();
  const [refresh, setRefresh] = useState(false);

  const [plansTableData, setPlansTableData] = useState({
    custodians: [],
    recordKeepers: [],
    productTypes: [],
  });

  const updateFetchData = useCallback((field, value) => {
    setPlansTableData(prev => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const fetchPlanHelpersData = async () => {
    const requests = [
      { field: 'custodians', request: get('custodians/get') },
      { field: 'recordKeepers', request: get('recordkeepers/get') },
      { field: 'productTypes', request: get('products/productTypes') },
    ];

    const results = await Promise.allSettled(
      requests.map(item => item.request),
    );

    requests.forEach((item, index) => {
      const { status, value } = results[index];
      const data = item.field === 'productTypes' ? value : value?.data;
      const result = status === 'fulfilled' ? data : '';
      updateFetchData(item.field, result);
    });
  };

  useEffect(() => {
    fetchPlanHelpersData();
  }, []);

  return (
    <TableDashboard
      pageTitle="Plans"
      navigatePath="/plans/add"
      setRefresh={setRefresh}
    >
      <PlanTable
        isLoading={isPending}
        refresh={refresh}
        setRefresh={setRefresh}
        plansTableData={plansTableData}
      />
    </TableDashboard>
  );
};

export default Plans;
