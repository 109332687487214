import { useState } from 'react';

import { TableDashboard } from '@atoms';
import useCsvExportButton from '@hooks/useCsvExportButton';

import AccountTable from './components/AccountTable';

const Accounts = () => {
  const [refresh, setRefresh] = useState(false);

  const [exportSearch, setExportSearch] = useState('');
  const [exportOrderBy, setExportOrderBy] = useState('');

  const {
    csvClickRef,
    csvData: { response: exportData = [] } = {},
    getExport,
    loadingResponse,
  } = useCsvExportButton();

  const handleCsvExport = () => {
    const queryParams = `?search=${exportSearch}${`&orderBy=${exportOrderBy}`}`;
    getExport(`reaccounts/export${queryParams}`);
  };

  return (
    <TableDashboard
      pageTitle="Retirement Edge Accounts"
      setRefresh={setRefresh}
      exportCsvProps={{
        fileName: 'REAccountsList',
        csvClickRef,
        csvData: exportData,
        getCsvExport: handleCsvExport,
        loadingResponse,
      }}
    >
      <AccountTable
        refresh={refresh}
        setRefresh={setRefresh}
        setExportSearch={setExportSearch}
        setExportOrderBy={setExportOrderBy}
      />
    </TableDashboard>
  );
};

export default Accounts;
