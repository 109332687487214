import { useCallback, useEffect, useMemo } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { Container, CustomInput } from 'reactstrap';

import { Spinner, Table, FormFooterActions } from '@atoms';
import { formatToPercentage } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';

const queryParams = [
  'search',
  'sortPlanName',
  'sortStatus',
  'planId',
  'from',
  'pageSize',
];

const handleNonEditableCell = ({ row, column }) => {
  const { index: rowIndex, original } = row;
  const { pioSelected } = original;
  const disabled = column.id === 'defaultPercent' && rowIndex;
  return !pioSelected ?? disabled;
};

const EditPIOs = (props) => {
  const {
    match: {
      params: { planProductId, productId },
    },
  } = props;

  const { get, post, isPending } = useApiFetch();
  const { showErrorToast } = useToast();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { planId, from: page, ...restParams } = getQueryParams(queryParams);

  const { setValue, watch, control } = useForm({
    defaultValues: {
      productPiosTable: [],
      pioIds: [],
    },
    mode: 'all',
  });

  const { productPiosTable, pioIds } = watch();

  const fetchPlanProductPios = async () => {
    const response = await get(
      `plans/planproducts/planproductpios?planProductID=${planProductId}&productID=${productId}`,
    );

    const formattedResponse = response?.data
      ?.map(item => ({
        ...item,
        defaultPercent: Number(item?.defaultPercent * 100).toFixed(2),
      }))
      ?.filter(({ status }) => status === 'Active');

    setValue('productPiosTable', formattedResponse);
  };

  useEffect(() => {
    fetchPlanProductPios();
  }, []);

  const selectedPios = productPiosTable
    ?.map((item) => {
      const isSelected = item?.pioSelected ? 1 : 0;
      return {
        ...item,
        pioSelected: isSelected,
      };
    })
    ?.filter(pio => pio?.pioSelected);

  const totalDefaultAllocation = useMemo(
    () => selectedPios?.length
      && selectedPios?.reduce(
        (acc, item) => acc + Number(item?.defaultPercent),
        0,
      ),
    [selectedPios],
  );

  useEffect(() => {
    if (productPiosTable?.length) {
      const selectedIds = productPiosTable
        ?.filter(item => item?.pioSelected)
        ?.map(({ pioid }) => ({ pioid }));

      setValue('pioIds', selectedIds);
    }
  }, [productPiosTable]);

  const navigatePathQuery = useMemo(() => {
    const query = buildQueryParams({
      page,
      id: planId,
      ...restParams,
    });
    return `/plans?${query}`;
  }, [page, planId, restParams]);

  const handleCheckboxChange = useCallback((pioid, rowIndex) => {
    const valueSelector = `productPiosTable.${rowIndex}`;
    const currentPioIds = watch('pioIds');
    setValue(`${valueSelector}.defaultPercent`, 0);

    const isSelected = currentPioIds?.some(val => val?.pioid === pioid);

    const updatedPioIds = isSelected
      ? currentPioIds.filter(id => id.pioid !== pioid)
      : [...currentPioIds, { pioid }];

    setValue('pioIds', updatedPioIds);
  }, []);

  const column = useMemo(
    () => [
      {
        id: 'pioSelected',
        size: '1%',
        cell: ({ row: { index: rowIndex, original } }) => {
          const { pioid } = original || {};
          const isSelectedPioIds = pioIds?.some(val => val?.pioid === pioid);

          return (
            <div key={pioid} className="flex">
              <Controller
                name={`productPiosTable.${rowIndex}.pioSelected`}
                control={control}
                render={({ field: { onChange, ref, ...rest } }) => {
                  const handleChange = () => {
                    onChange(!isSelectedPioIds);
                    handleCheckboxChange(pioid, rowIndex);
                  };

                  return (
                    <CustomInput
                      {...rest}
                      type="checkbox"
                      innerRef={ref}
                      id={pioid}
                      onChange={handleChange}
                      checked={isSelectedPioIds}
                    />
                  );
                }}
              />
            </div>
          );
        },
      },
      {
        id: 'pioName',
        header: 'Investment Option',
        size: '45%',
        cell: ({ row }) => <div className="m-0">{row?.original?.pioName}</div>,
      },
      {
        id: 'defaultPercent',
        header: 'Default Percent',
        alignRight: true,
        size: '10%',
        accessorKey: 'defaultPercent',
      },
      {
        id: 'status',
        header: 'Status',
        size: '30%',
        alignCenter: true,
        cell: ({ row }) => (
          <div className="column-center">{row?.original?.status}</div>
        ),
      },
    ],
    [pioIds, handleCheckboxChange],
  );

  const onSubmit = useCallback(() => {
    const payloadValues = selectedPios?.map(item => ({
      pioid: item?.pioid,
      defaultPercent: Number(item?.defaultPercent) / 100,
    }));

    if (!selectedPios.length) {
      showErrorToast('At least one Investment Option must be selected.');
    } else if (totalDefaultAllocation !== 100) {
      showErrorToast('Total Allocation must be 100');
    } else {
      post(
        `plans/planproducts/planproductpios?planproductid=${planProductId}`,
        payloadValues,
        { redirectUrl: navigatePathQuery },
      );
    }
  }, [selectedPios, navigatePathQuery, planProductId, totalDefaultAllocation]);

  const tableFooter = useMemo(
    () => (
      <>
        <div className="mt-3">
          <h4 className="font-weight-bold text-center">
            Total Default Allocation{' '}
            {formatToPercentage(totalDefaultAllocation)}
          </h4>
        </div>
        <FormFooterActions
          linkProps={[{ path: navigatePathQuery }]}
          buttonProps={[{ onClick: onSubmit }]}
        />
      </>
    ),
    [navigatePathQuery, onSubmit, totalDefaultAllocation],
  );

  return (
    <Container className="editable_cell_bg_none">
      <h3 className="mb-3">Product Investment Options</h3>
      <Spinner requesting={isPending}>
        <Table
          columns={column}
          data={productPiosTable}
          hidePagination
          footer={tableFooter}
          isEditable
          editableProps={{
            control,
            tableName: 'productPiosTable',
            maxLength: 10,
            shouldPercentage: true,
            numericOnly: true,
            alignRight: true,
            disableFn: handleNonEditableCell,
          }}
        />
      </Spinner>
    </Container>
  );
};

export default EditPIOs;
