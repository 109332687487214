import { useCallback, useMemo, useState } from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { imagePath } from '@config/url/images';
import { getImageUrl } from '@utils/url';

import AnnuityPolicyDetail from './AnnuityPolicyDetail';
import Summary from './Summary';
import TabPanel from './TabPanel';

const allianzLogo = getImageUrl(imagePath.allianzLogo);
const annuityDetailsTabs = [
  { label: 'Pre-Tax Policy', taxCode: 'PreTax' },
  { label: 'Roth Policy', taxCode: 'Roth' },
  { label: 'After-Tax Policy', taxCode: 'AfterTax' },
];

const AnnuityTabs = ({ data, tabShown }) => {
  const [value, setValue] = useState(tabShown);

  const handleChange = useCallback((_event, newValue) => {
    sessionStorage.setItem('activeTab', newValue);
    setValue(newValue);
  }, []);

  const handleChangeTab = useCallback((newValue) => {
    sessionStorage.setItem('activeTab', newValue);
    setValue(newValue);
  }, []);

  const tableData = useMemo(() => {
    const filteredData = data?.filter(
      item => item?.policyNumber !== 'Pending',
    );
    return filteredData?.length ? filteredData : data;
  }, [data]);

  const showTab = (taxCode) => {
    const validTaxPolicy = tableData
      ?.map(({ taxCode }) => taxCode)
      .find(tax => tax === taxCode);

    return !!validTaxPolicy;
  };

  return (
    <div className="annuityTabs">
      <div className="flex justify-content-between align-items-center">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="participant's annuity information"
        >
          <Tab label="Summary" />
          {annuityDetailsTabs.map(({ label, taxCode }) => {
            const isShownTab = showTab(taxCode);
            return isShownTab ? <Tab label={label} key={label} /> : null;
          })}
        </Tabs>
        <img className="product_logo" src={allianzLogo} />
      </div>
      <TabPanel value={value} index={0}>
        <Summary data={tableData} tabClick={handleChangeTab} />
      </TabPanel>
      {tableData?.map(({ taxCode }, index) => (
        <TabPanel value={value} index={index + 1} key={taxCode}>
          <AnnuityPolicyDetail
            taxSource={taxCode}
            data={tableData}
            key={taxCode}
          />
        </TabPanel>
      ))}
    </div>
  );
};

export default AnnuityTabs;
