import { useState, useEffect, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Container } from 'reactstrap';

import { Spinner, Table } from '@atoms';
import { useAuth } from '@contexts/AuthContext';
import useApiFetch from '@hooks/useApiFetch';
import { formatDate } from '@lib/date';

import Navbar from './Navbar';

const AnnuityDocumentVault = () => {
  const { userFirstName, annuityAuthToken, reAccountNumber } = sessionStorage || {};
  const { annuityLogout } = useAuth();
  const windowLocationPath = sessionStorage.getItem('window.location.pathname');
  let navigateBackUrl = `${windowLocationPath}?token=${annuityAuthToken}`;

  const { get, isPending } = useApiFetch();
  const [annuityDocumentData, setAnnuityDocumentData] = useState([]);

  let isAnnuitantSystemUser = true;
  if (windowLocationPath?.includes('GenericUser')) {
    isAnnuitantSystemUser = false;
    navigateBackUrl = `${windowLocationPath}`;
  }

  const getData = useCallback(async () => {
    const response = await get(
      `reaccounts/annuitydocumentvault/${reAccountNumber}/${isAnnuitantSystemUser}`,
    );
    setAnnuityDocumentData(response?.data);
  }, [isAnnuitantSystemUser]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDocumentView = async (docId) => {
    const data = await get(`reaccounts/annuitydocumentvault/document/${docId}`);
    if (data?.data?.documentURL) {
      window.open(data?.data?.documentURL, '_blank');
    }
  };

  const columns = [
    {
      id: 'view',
      header: 'View',
      alignCenter: true,
      size: '10%',
      cell: (rows) => {
        const { documentID } = rows?.row?.original || {};
        return (
          <div
            className="pointer text-center"
            onClick={() => handleDocumentView(documentID)}
          >
            <i
              class="fa fa-file-text fa-lg"
              style={{ color: 'rgb(113, 153, 187)' }}
            />
          </div>
        );
      },
    },
    {
      id: 'documentTitle',
      header: 'Document Title',
      accessorKey: 'documentTitle',
    },
    {
      id: 'policyNumber',
      header: 'Policy Number',
      size: '15%',
      accessorKey: 'policyNumber',
    },
    {
      id: 'type',
      header: 'Type',
      size: '15%',
      accessorKey: 'taxCode',
    },
    {
      id: 'category',
      header: 'Category',
      size: '15%',
      accessorKey: 'documentType',
    },
    {
      id: 'postDate',
      header: 'Post Date',
      alignRight: true,
      size: '10%',
      cell: (rows) => {
        const { documentPostedDate } = rows?.row?.original || {};
        return (
          <div className="text-right">
            {formatDate(documentPostedDate, 'M/DD/YYYY')}
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <Spinner requesting={isPending}>
        <Navbar
          userFirstName={userFirstName}
          invalidateAnnuitantToken={annuityLogout}
        />
        <Container className="annuity_documents_wrapper">
          <div className="my-4">
            <Button href={navigateBackUrl} variant="outlined" color="primary">
              Back to Policy Page
            </Button>
          </div>
          <Typography className="my-4 d-block" variant="button">
            Statements & Policy Documents
          </Typography>
          <Paper>
            <Table
              columns={columns}
              data={annuityDocumentData}
              hidePagination
              noPadding
            />
          </Paper>
        </Container>
      </Spinner>
    </Container>
  );
};

export default AnnuityDocumentVault;
