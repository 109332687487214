import { useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';

import {
  formatCurrency,
} from '@containers/components/helpers';
import { InfoOutlineIcon } from '@shared/icons';

import AllocationMix from './AllocationMix';
import AnnuityDocumentsPage from './AnnuityDocumentsPage';
import LifeTimeIncomeBenefitsDetails from './LifeTimeIncomeBenefits/LifeTimeIncomeBenefitsDetails';
import ServiceCenter from './ServiceCenter';
import '@scss/containers/annuity-page.scss';

const currentDate = new Date()?.toLocaleDateString('en-US');

const AnnuityPolicyDetail = (props) => {
  const { taxSource, data = [] } = props;

  sessionStorage.setItem('window.location.pathname', window.location.pathname);
  const [cashValueModal, setCashValueModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const onToggleCashValueModal = () => setCashValueModal(!cashValueModal);
  const openCashValueModal = () => setCashValueModal(true);
  const policyData = useMemo(
    () => data.find(d => d?.taxCode === taxSource),
    [data],
  );

  const {
    policyAccumulationValue,
    policySurrenderValue,
    policyNumber,
    policyIssueDate,
    dob,
    pipRates,
  } = policyData || {};

  const allocationsData = useMemo(() => {
    const allocations = data.find(val => val?.taxCode === taxSource) || {};
    const allocData = allocations.productInvestmentOptionAllocations;
    return allocData;
  }, [taxSource, data]);

  const accumulationValue = policyAccumulationValue
    ? formatCurrency(policyAccumulationValue)
    : '$ 0.00';

  const surrenderValue = policySurrenderValue
    ? formatCurrency(policySurrenderValue)
    : '$ 0.00';

  const renderAnnuityCashModal = () => (
    <Modal
      isOpen={cashValueModal}
      toggle={onToggleCashValueModal}
      className="annuity-cash-Modal"
      size="sm"
      centered
    >
      <div className="modal__header text-right">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          aria-label="close lightbox button"
          onClick={onToggleCashValueModal}
        />
      </div>
      <ModalBody className="modal-body">
        <span className="bold-text">
          As of {currentDate}, the cash value of your annuity if liquidated is{' '}
          {surrenderValue}.
        </span>
        <span>
          The cash value is equal to the accumulation value plus any market
          value adjustment (MVA). The MVA is an adjustment to reflect changing
          market conditions. It can be either a positive or negative adjustment
          to the contract value and may increase or decrease your contract value
          if a withdrawal occurs. Allianz applies the MVA only to premiums that
          have been in the contract less than 10 contract years if you:
        </span>
        <ul className="modal-list">
          <li>Take a full withdrawal;</li>
          <li>Begin annuity payments before the 5th contract anniversary;</li>
          <li>
            Take a partial withdrawal that is not a lifetime withdrawal, a
            cumulative withdrawal, or required minimum distribution.
          </li>
        </ul>
      </ModalBody>
    </Modal>
  );

  return (
    <Grid
      spacing={1}
      container
      justifyContent="space-around"
      alignItems="flex-start"
      className="annuity_policy_wrapper"
    >
      <Grid item xs={6} sm={6}>
        <div className="accumulation_card p-3 text-white">
          <div className="accumulation_title mb-2">
            <div className="tooltip_wrapper">
              <div className="MuiTypography-h6">Accumulation Value</div>
              <InfoOutlineIcon id="tooltip" size="20px" />
              <UncontrolledTooltip
                placement="bottom"
                isOpen={tooltipOpen}
                toggle={toggleTooltip}
                target="tooltip"
              >
                This value is equal to your total premium paid plus fixed or
                indexed interest less withdrawals
              </UncontrolledTooltip>
            </div>
            <div className="accumulation_value_wrapper">
              <div>
                <span className="MuiTypography-h6">
                  {accumulationValue.split('.')[0]}
                </span>
                <sup className="sc-fotOHu dfigil">
                  {accumulationValue.split('.')[1]}
                </sup>
              </div>

              <Button
                target="_blank"
                className="bg-light text-transform-none"
                onClick={openCashValueModal}
              >
                View cash value
              </Button>
            </div>
          </div>

          <div className="divider w-100" />
          <div className="contract_details">
            <div>
              <span className="MuiTypography-button">contract n</span>
              <sup className="sc-fotOHu dfigil">o</sup>
              <span className="pl-1">{policyNumber}</span>
            </div>
            <div>
              <span className="MuiTypography-button">contract Date</span>
              <span className="pl-1">{policyIssueDate || 'Pending'}</span>
            </div>
          </div>
        </div>
        <div className="p-4 mt-3 border rounded">
          <Typography variant="h6">Your Crediting Options</Typography>
          <AllocationMix data={allocationsData} />
        </div>
        <Grid container>
          <Grid item className="w-100 mt-3 p-2 border rounded">
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <i className="fa fa-phone-circle fa-3x icon-blue" />
              </Grid>
              <Grid item xs={11}>
                <ServiceCenter
                  data={data.find(a => a.taxCode === taxSource)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AnnuityDocumentsPage />
      </Grid>
      <LifeTimeIncomeBenefitsDetails
        policyData={policyData}
        data={data}
        taxSource={taxSource}
      />
      {renderAnnuityCashModal()}
    </Grid>
  );
};

export default AnnuityPolicyDetail;
