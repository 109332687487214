import React, { useCallback, useState } from 'react';

import { UncontrolledTooltip, Button } from 'reactstrap';

import { TableDashboard } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';

import CashLedgerTable from './components/CashLedgerTable';

const CashLedger = () => {
  const { post, isPending } = useApiFetch();

  const [refresh, setRefresh] = useState(false);
  const [pendingRecords, setPendingRecords] = useState(null);

  const handleUpdate = useCallback(async () => {
    await post('operations/cashledger/updatedailysummary');
    setRefresh(true);
  }, [refresh]);

  const renderUpdateButton = () => (
    <>
      {pendingRecords ? (
        <>
          <Button color="primary" id="update_record" onClick={handleUpdate}>
            {isPending ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              'Update'
            )}
          </Button>
          <UncontrolledTooltip placement="left" target="update_record">
            {pendingRecords} records pending.
          </UncontrolledTooltip>
        </>
      ) : null}
    </>
  );

  return (
    <TableDashboard
      pageTitle="Cash Ledger"
      setRefresh={setRefresh}
      additionalChild={renderUpdateButton}
    >
      <CashLedgerTable
        refresh={refresh}
        setRefresh={setRefresh}
        setPendingRecords={setPendingRecords}
      />
    </TableDashboard>
  );
};

export default CashLedger;
