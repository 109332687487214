import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import CustodiansForm from '../CustodiansForm/CustodiansForm';

const queryPath = ['from', 'pageSize'];

const EditCustodian = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { get, isPending } = useApiFetch();
  const { getQueryParams } = useQueryParams();
  const [custodians, setCustodians] = useState({});

  const { from: page, pageSize } = getQueryParams(queryPath);

  const getSingleCustodian = useCallback(async () => {
    const response = await get(`custodians/${id}`);
    setCustodians(response);
  }, [id]);

  useEffect(() => {
    getSingleCustodian();
  }, []);

  const redirectUrl = useMemo(
    () => `/custodians?page=${page}${pageSize ? '&pageSize=' + pageSize : ''}`,
    [page, pageSize],
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Custodian Detail</h3>

      <FormSpinner requesting={isPending} />
      <CustodiansForm data={custodians} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditCustodian;
