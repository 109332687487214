import { Controller } from 'react-hook-form';
import Input from 'reactstrap/lib/Input';

const TableSearchInput = ({
  name,
  control,
  rules,
  defaultValue,
  inputType = 'text',
  className,
  placeholder,
  selectOptions = [],
  style,
  maxLength,
  firstFieldEmpty = false,
}) => {
  const options = firstFieldEmpty
    ? [{ label: '', value: '' }, ...selectOptions]
    : selectOptions;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          {...field}
          type={inputType}
          placeholder={placeholder}
          className={`table__search-input table__search mb-0 ${className}`}
          maxLength={maxLength}
          style={style}
        >
          {inputType === 'select'
            ? options?.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
              ))
            : null}
        </Input>
      )}
    />
  );
};

export default TableSearchInput;
