import { useRef } from 'react';

import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import { formatDate } from '@lib/date';

const DateRangePicker = ({
  name,
  defaultValue,
  control,
  placeholder,
  rules,
  label,
  labelClassName,
  datePickerVariant = 'form',
}) => {
  const inputRef = useRef(null);
  const isRequired = rules?.required;

  const renderDatePicker = (onChange, rest) => (
    <ReactDatePicker
      {...rest}
      ref={inputRef}
      selected={rest?.value ? new Date(rest?.value) : null}
      onChange={date => onChange(formatDate(date))}
      placeholderText={placeholder}
      className={
        datePickerVariant === 'table'
          ? 'form-control table__search table__search-input mb-0'
          : ''
      }
    />
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...rest }, formState: { errors } }) => (datePickerVariant === 'form' ? (
        <div className="form__form-group">
          {label && (
          <div className={`form__form-group-label ${labelClassName || ''}`}>
            {label}
            {isRequired && <span className="text-danger"> *</span>}
          </div>
            )}
          <div className="form-date-picker form__form-group-field form__form-group-input-wrap--error-above">
            {renderDatePicker(onChange, rest)}
            {errors?.[name] && (
            <span className="form__form-group-error">
              {errors?.[name]?.message}
            </span>
              )}
          </div>
        </div>
        ) : (
          renderDatePicker(onChange, rest)
        ))}
    />
  );
};

export default DateRangePicker;
