import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { sanitizeValue } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import { getTarget } from '@utils/env';

import EditAccountForm from './components/EditAccountForm';

const targetLink = getTarget();
const queryParams = [
  'from',
  'search',
  'pageSize',
  'sortAccountNumber',
  'sortParticipantName',
  'sortPolicyNumber',
];

const EditAccount = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const {
 get, put, post, isPending,
} = useApiFetch();

  const [fetchData, setFetchData] = useState({
    accountDetail: {},
    annuityUserToken: {},
  });

  const { token } = useAuth();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const {
    from: page,
    search,
    pageSize,
    sortAccountNumber,
    sortParticipantName,
    sortPolicyNumber,
  } = getQueryParams(queryParams);

  const { accountDetail, annuityUserToken } = fetchData || {};

  const commonPath = useMemo(
    () => buildQueryParams({
        search,
        sortAccountNumber,
        sortParticipantName,
        sortPolicyNumber,
        pageSize,
      }),
    [pageSize, search, sortAccountNumber, sortParticipantName, sortPolicyNumber],
  );

  const getAccountDetails = useCallback(async () => {
    const response = await get(`reaccounts/${id}`);
    setFetchData(prev => ({ ...prev, accountDetail: response }));
  }, [id]);

  const getAnnuityUserTokens = useCallback(async () => {
    const credentials = {
      Token: token,
      loginName: 'annuitysystemuser',
      userType: 'Annuitant',
    };
    const response = await post('loginAnnuityUser', credentials, {
      successToast: false,
    });
    setFetchData(prev => ({
      ...prev,
      annuityUserToken: response?.data?.token,
    }));
  }, []);

  useEffect(() => {
    getAnnuityUserTokens();
    getAccountDetails();
  }, [getAccountDetails, getAnnuityUserTokens]);

  const handleForm = (values) => {
    const payloadValues = {
      notes: sanitizeValue(values?.notes),
    };

    put(`reaccounts/${id}`, payloadValues, {
      redirectUrl: `/accounts?page=${page}&${commonPath}`,
    });
  };

  const isIpxHosted = accountDetail?.ipxHosted;

  // https://happy-grass-0e0eb9f10.1.azurestaticapps.net/
  const lnkhref = `${
    window.location.protocol
  }//${targetLink}/View/Account/Summary/${
    accountDetail.reAccountNumber
  }?token=${encodeURI(annuityUserToken)}`;

  return (
    <Container>
      <h3 className="mb-3 text-center">
        RE Account Detail
        {isIpxHosted ? (
          <a
            href={lnkhref}
            target="_blank"
            rel="noreferrer"
            className="mb-3 btn btn-primary float-right"
          >
            Accounts Detail
          </a>
        ) : null}
      </h3>
      <FormSpinner requesting={isPending} />
      <div className="pb-5">
        <EditAccountForm
          data={accountDetail}
          from={page}
          commonPath={commonPath}
          onSubmit={handleForm}
          id={id}
        />
      </div>
    </Container>
  );
};

export default EditAccount;
