import React from 'react';

import LogInForm from './LogInForm';

const LogIn = () => {
  if (window.location.pathname === '/Profile'.toLowerCase()) {
    window.location.reload();
  }

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              Welcome to
              <span className="account__logo">
                <span className="account__logo-accent"> Retirement Edge</span>
              </span>
            </h3>
          </div>
          <LogInForm />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
