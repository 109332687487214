import {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Container } from 'reactstrap';

import { FormSpinner } from '@containers/FormSpinner';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';

import RecordKeepersForm from '../RecordKeepersForm/RecordKeepersForm';

const queryParams = [
  'from',
  'sortRecordkeeperName',
  'sortRKChannel',
  'sortStatus',
  'search',
  'pageSize',
];

const EditRecordKeepers = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { get, isPending } = useApiFetch();
  const { getQueryParams, buildQueryParams } = useQueryParams();

  const { from: page, ...restParams } = getQueryParams(queryParams);

  const [recordKeepers, setRecordKeepers] = useState({});

  const getSingleRecordKeeper = useCallback(async () => {
    const response = await get(`recordkeepers/${id}`);
    setRecordKeepers(response?.data);
  }, [id]);

  useEffect(() => {
    getSingleRecordKeeper();
  }, [getSingleRecordKeeper]);

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({ page, ...restParams });

    return `/recordkeepers?${queryParams}`;
  }, [page, restParams]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Recordkeeper Detail</h3>
      <FormSpinner requesting={isPending} />
      <RecordKeepersForm data={recordKeepers} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditRecordKeepers;
