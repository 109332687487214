import { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';

import { Input, Select, FormFooterActions } from '@atoms';
import { msgs, statusOptions } from '@config/conf';
import {
  filterOptions,
  formatDateWithTime,
  taxIdFormat,
} from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import { emailPattern, zipcodePattern } from '@shared/helpers';

const country = 'USA';

const incomeActivationStatusOptions = [
  { value: 'In Plan', label: 'In Plan' },
  { value: 'Out of Plan', label: 'Out of Plan' },
];

const PlanForm = ({ data, redirectUrl, onSubmit }) => {
  const { get, isPending } = useApiFetch();
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      countryCode: country,
    },
  });

  const [planTypes, setPlanTypes] = useState([]);
  const [countryStates, setCountryStates] = useState([]);
  const [custodiansData, setCustodianData] = useState([]);
  const [recordKeepers, setRecordKeepers] = useState([]);

  const getCustodians = async () => {
    const response = await get('custodians/get');
    setCustodianData(response?.data);
  };

  const getRecordKeepers = async () => {
    const response = await get('recordkeepers/get');
    setRecordKeepers(response?.data);
  };

  const getStates = async () => {
    const countryData = await get(`countrystates/${country}`);

    if (countryData?.data?.length) {
      setCountryStates(countryData?.data);
    }
  };

  const getPlanTypes = async () => {
    const response = await get('plans/plantypes');
    setPlanTypes(response?.data);
  };

  const commonProps = {
    control,
    required: true,
    rules: {
      required: msgs.fieldError,
    },
  };

  const recordKeeperOptions = useMemo(
    () => filterOptions(recordKeepers, 'recordkeeperID', 'recordkeeperName'),
    [recordKeepers],
  );

  const stateOptions = useMemo(
    () => filterOptions(countryStates, 'stateCode', 'stateName'),
    [countryStates],
  );

  const planTypeOptions = useMemo(
    () => filterOptions(planTypes, 'planTypeId', 'planTypeDescription'),
    [planTypes],
  );

  const custodianOptions = useMemo(
    () => filterOptions(custodiansData, 'custodianID', 'custodianName'),
    [custodiansData],
  );

  useEffect(() => {
    reset({
      ...data,
      recordkeeperID: !data?.planID
        ? recordKeeperOptions?.[0]?.value
        : data?.recordkeeperID,
      planCreatedOn: data?.planCreatedOn
        ? formatDateWithTime(data.planCreatedOn)
        : '',
      federalTaxID: data?.federalTaxID ? taxIdFormat(data?.federalTaxID) : '',
      planUpdatedOn: data?.planUpdatedOn
        ? formatDateWithTime(data.planUpdatedOn)
        : '',
    });

    getRecordKeepers();
    getCustodians();
    getStates();
    getPlanTypes();
  }, [data]);

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          <Select
            {...commonProps}
            name="recordkeeperID"
            label="Recordkeeper"
            placeholder="Select Recordkeeper"
            options={recordKeeperOptions}
            isLoading={recordKeepers?.requesting}
            errorMessage={recordKeepers?.err}
          />
          <Input
            {...commonProps}
            label="Plan Name"
            name="planName"
            maxLength="200"
          />
          <Select
            {...commonProps}
            name="planTypeId"
            label="Plan Type"
            placeholder="Select Plan Type"
            options={planTypeOptions}
            isLoading={!planTypeOptions?.length}
            loadingText="Select Plan Type"
          />
          <Input
            {...commonProps}
            label="Federal Tax ID"
            name="federalTaxID"
            maxLength="15"
            formatter={{
              numericOnly: true,
              withComma: false,
              formatType: 'taxId',
            }}
          />
          <Input
            {...commonProps}
            label="Carrier Plan ID"
            name="carrierPlanIdentifier"
            maxLength="25"
          />
          <Input
            {...commonProps}
            label="Address 1"
            name="address1"
            maxLength="100"
          />
          <Input
            control={control}
            label="Address 2"
            name="address2"
            maxLength="100"
          />
          <Input {...commonProps} label="City" name="city" maxLength="100" />
          <Select
            {...commonProps}
            label="State"
            name="stateCode"
            options={stateOptions}
            isLoading={!countryStates?.length}
            loadingText="Select State"
          />
          <Input
            control={control}
            required
            rules={{
              required: msgs.fieldError,
              pattern: {
                value: zipcodePattern,
                message: 'Zip Code is invalid.',
              },
            }}
            label="Zip"
            name="zip"
            maxLength="50"
          />
          <Input
            {...commonProps}
            label="Country"
            name="countryCode"
            maxLength="6"
            isReadOnly
          />
          <Input
            control={control}
            label="Contact Name"
            name="contactName"
            maxLength="50"
          />
          <Input
            control={control}
            label="Contact Email"
            name="contactEmail"
            maxLength="100"
            rules={{
              pattern: {
                value: emailPattern,
                message: 'Email is invalid.',
              },
            }}
          />
          <Input
            control={control}
            label="Contact Fax"
            name="contactFax"
            maxLength="50"
          />
          <Input
            control={control}
            label="Contact Phone"
            name="contactPhone"
            maxLength="50"
          />
          <Select
            {...commonProps}
            label="Custodian"
            name="custodianID"
            placeholder="Select Custodian"
            options={custodianOptions}
            isLoading={isPending}
          />
          <Input
            control={control}
            label="Custodial Account Reference"
            name="custodialAccount"
            maxLength="50"
          />
          <Input
            control={control}
            label="Custodial Trust Account"
            name="custodialTrustAccount"
            maxLength="20"
          />
          <Input
            control={control}
            label="Custodial TPA Code"
            name="custodialTpaCode"
            maxLength="20"
          />

          <Input
            control={control}
            label="Record Keeper Account Reference"
            name="rkAccount"
            maxLength="20"
          />
          <Input
            control={control}
            label="Notes"
            type="textarea"
            name="notes"
            maxLength="2000"
          />
          <Select
            {...commonProps}
            label="Status"
            name="status"
            options={statusOptions}
          />
          <Select
            {...commonProps}
            label="Income Activation Status"
            name="incomeActivationStatus"
            placeholder="Select Income Activation Status"
            options={incomeActivationStatusOptions}
          />
          {data?.planID ? (
            <>
              <Input
                control={control}
                label="Plan Created"
                name="planCreatedOn"
                isReadOnly
              />
              <Input
                control={control}
                label="Plan Updated"
                name="planUpdatedOn"
                isReadOnly
              />
              <Input
                control={control}
                label="Last Updated By"
                name="lastUpdatedByUserName"
                isReadOnly
              />
            </>
          ) : null}
        </div>
        <FormFooterActions
          linkProps={[{ path: redirectUrl }]}
          buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
        />
      </CardBody>
    </Card>
  );
};

export default PlanForm;
